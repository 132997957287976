.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }
  
  .modal {
    background-color: white;
    padding: 2rem;
    max-width: 90vw;
    max-height: 90vh;
    overflow: auto;
    border-radius: 8px;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.3);
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .modal-header h2 {
    margin: 0;
  }
  
  .modal-header button {
    background-color: transparent;
    border: none;
    font-size: 2rem;
    cursor: pointer;
  }
  .form-field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .form-field label {
    margin-right: 10px;
    flex-basis: 30%;
    text-align: left;
  }
  
  .form-field input,
  .form-field select,
  .form-field textarea {
    flex-basis: 65%;
  }